import { Link } from "gatsby"
import React from "react"
import "./CTAButton.scss"

/**
 * Button - returns a styled <Link>
 * @param {*} props 
 * @param {string} text text in button
 * @param {string} link url to point to (must be internal link)
 * @param {string} color either "default" or "dark"
 * @param {string} size either "default" or "large"
 * @param {object} style additional or overriding css
 */
function CTAButton(props) {
  const {text, link, color="default", style, size="default"} = props

  const baseStyle = {
    fontWeight: 'var(--weight--bold)',
    borderRadius: '2rem',
    display: 'inline-block',
    boxShadow: 'var(--shadow)',
    textDecoration: 'none',
  }
  const defaultColor = {
    backgroundColor: 'var(--color--cta)',
    color: 'var(--color--text-on-cta)',
  }
  const darkColor = {
    backgroundColor: 'var(--color--bg)',
    color: 'var(--color--text--inverse)',
  }
  const defaultSize = {
    fontSize: 'var(--scale--sub1)',
    padding: '0.25em 0.85em',
  }
  const largeSize = {
    fontSize: 'var(--scale--0)',
    padding: '0.8em 1.85em',
  }
  const sizeStyle = size === 'large' ? largeSize : defaultSize
  const colorStyle = color === 'dark' ? darkColor : defaultColor

  const buttonStyle = {
    ...baseStyle,
    ...colorStyle,
    ...sizeStyle,
    ...style,
  }
  return (
    <Link className={`button button--${color} button--${size}`} to={link} style={buttonStyle}>
      {text}
    </Link>
  )
} 

export default CTAButton
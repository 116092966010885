import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import React from "react"
import IconInline from '../IconInline/IconInline'
import SectionHeading from '../SectionHeading/SectionHeading'
import "./HomepageWork.scss"


function HomepageWork() {
  const linksData = [
    {
      href: "https://academy.allaboutbirds.org",
      name: "Bird Academy"
    },
    {
      href: "http://zevross.com/", 
      name: "Zev Ross"
    },
    {  
      href: "https://academy.allaboutbirds.org/features/flaptothefuture/",
      name: "Flap to the Future"
    },
    {  
      href: "https://academy.allaboutbirds.org/features/beastbox/",
      name: "BeastBox"
    },
    {  
      href: "https://academy.allaboutbirds.org/features/wallofbirds/",
      name: "Wall of Birds"
    }
  ]

  const divStyle = {
    backgroundColor: "var(--color--bg)",
    backgroundSize: "cover",
    backgroundPosition: "center top",
    minHeight: "90vh"
  };
  const listStyle = {
    paddingLeft: "0",
    textAlign: "right"
  };
  const listItemStyle = {
    fontSize: "var(--scale--4)",
    fontStyle: "italic",
    paddingRight: "0.3em",
    paddingLeft: "1em",
    display: "inline-block",
    listStyle: "none"
  };

  function WorkLink(props) {
    const {href, name} = props
    return (
      <li style={listItemStyle}>
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontWeight:  "var(--weight--bold)" }}
        >
          {name}
          <IconInline 
            name="external" 
            width={28} 
            height={28} 
            style={{transform: "translateX(0.6ch)", fontSize: "var(--scale--2)", width: "1em"}}
          />
        </a>
      </li>
    );
  }

  // light color: #6d5397
  // dark color: #272131
  // light gray: #737373
  // dark gray: #191919
  return (
    <StaticQuery
      query={graphql`
        query {
          backgroundImage: file(relativePath: { eq: "assets/theme/mural-chickadee.jpg" }) {
            childImageSharp {
              fluid(
                quality: 90,
                maxWidth: 1920,
                duotone: {
                  highlight: "#737373",
                  shadow: "#191919"
                }
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <BackgroundImage
            Tag="section"
            fluid={data.backgroundImage.childImageSharp.fluid}
            id="homepage__work"
            className="inverse container grid grid--outer grid__padding banner--work"
            aria-labelledby="section-heading--work"
            style={divStyle}
          >
            
            <SectionHeading
              title="Living Project Samples"
              sub="Work as seen in the wild"
              id="section-heading--work"
              inverse
            />
            <ul className="work__list" style={listStyle}>
              {linksData.map( (item, i) => (
                <WorkLink key={i} href={item.href} name={item.name} />
              ))}
            </ul>
          </BackgroundImage>
        )
      }}
    />
  );
}

export default HomepageWork;

import React from "react";
import Graphic from "../../../static/assets/theme/homepage__fit.inline.svg";
import "./HomepageFit.scss";

function HomepageFit() {
  return (
    <section id="homepage__fit" className="container grid grid--outer">
      <div 
        className="homepage__fit__text"
        style={{ boxShadow: "var(--shadow)"}}  
      >
        <h2 style={{margin: "0"}}>Working with Jeff&nbsp;Szuc</h2>
        <small style={{ color: "var(--color--text--min-contrast)"}}>
          A Good Fit
        </small>
        <p style={{
            paddingBottom: "var(--spacing--1)",
            borderBottom: "var(--border-width--0) solid currentColor"
          }}
        >
          Great projects are the product of great teams; I collaborate closely with marketing departments, programmers, subject matter experts, editors, and other designers. My strong work ethic and diverse skill-set are a great addition to any project. I’m a good fit.
        </p>
      </div>
      <div className="homepage__fit__graphic">
        <Graphic style={{maxWidth: "24rem"}} />
      </div>
    </section>
  );
}

export default HomepageFit;
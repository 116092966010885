import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import CTAButton from '../CTAButton/CTAButton'
import PostTags from '../PostTags/PostTags'
import "./HomepageFeatured.scss"



const _ = require("lodash")

function HomepageFeatured(props) {
  const { 
    frontmatter: { title, category, tags, summary, thumb, type }, 
    fields: { slug }
    } = props
  const featuredImage = thumb.childImageSharp.gatsbyImageData;

  return (
    <section
      className='container grid grid--outer featured-post inverse'
      aria-labelledby={`${_.kebabCase(title)}`}
      style={{ backgroundColor: "var(--color--bg)"}}
    >

      <Link
        aria-labelledby={`${_.kebabCase(title)}`}
        className="featured__image__link"
        to={slug}
      >
        <GatsbyImage
          className="featured__image"
          alt=""
          image={featuredImage}
          width={500}
          height={500}
        />
      </Link>

      <div className="featured-post__content">
        <div className="featured-post__meta">
          <Link
            className="featured-post__category"
            to={`/categories/${_.kebabCase(category)}`}
            style={{ marginRight: "1ch" }}
          >
            {`${category}:`}
          </Link>
          <PostTags
            tags={tags}
            style={{
              color: "currentColor",
              textDecoration: "none"
            }}
          />
        </div>

        <Link
          id={`${_.kebabCase(title)}`}
          className="featured-post__title h2"
          to={slug}
          style={{
            display: "block",
            marginTop: 0,
            marginBottom: "var(--spacing--1)"
          }}
        >
          {title}
        </Link>

        <p>{summary}</p>
        <CTAButton text={`Read the full ${type}`} link={slug} size="large" />

      </div>
    </section>
  )
}


export default HomepageFeatured;
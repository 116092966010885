import React from "react"
import Graphic from "../../../static/assets/theme/homepage__rocket.inline.svg"
import IconInline from '../IconInline/IconInline'
import SectionHeading from '../SectionHeading/SectionHeading'
import "./HomepageIntro.scss"



const data = [
  {
    name: "Shiny Visuals",
    icon: "triangle",
    content: "Combine the latest design trends with a strong foundation of experience. Pay attention to details; that is what distinguishes professional products."
  },
  {
    name: "Robust Design",
    icon: "square",
    content: "More than shiny visuals, follow best practices for optimization, SEO, usability, and accessibility. Test and document design decisions and implementations."
  },
  {
    name: "Big Picture",
    icon: "circle",
    content: "Design on-brand and consider development times, usability, and stakeholder requirements. Predict and avoid scaling and maintenance issues. Make decisions based on analytics, A/B, and usability testing."
  }
]

function Principle({title, icon, i, content}) {
  const cardStyle = {
    borderBottom: "var(--border-width--0) solid currentColor",
    paddingTop: "0.25rem"
  }

  const iconStyle = {
    height: "1.5em",
    marginRight: "0.25em"
  }

  return (
    <div className={`card card--${i}`} style={cardStyle}>
      <h3 style={{marginTop: "var(--scale--sub3)"}}>
        <IconInline name={icon} style={iconStyle} height={40} />
        {title}
      </h3>
      <p>{content}</p>
    </div>
  )
}

function HomepageIntro() {
    return (
      <section
        id="homepage__design"
        className="container grid grid--outer grid__padding"
        aria-labelledby="section-heading--design"
        style={{ backgroundColor: "var(--color--body)"}}
      >
        <SectionHeading
          title="Design Principles"
          sub="End-to-end Product Design"
          id="section-heading--design"
        />

        {data.map((principle, i) => (
          <Principle 
            key={i}
            title={principle.name} 
            icon={principle.icon} 
            i={i} 
            content={principle.content}
          />
          )
        )}
        <Graphic className="intro__illustration" />
      </section>
    );
  }

export default HomepageIntro

import React from "react";
import Graphic from "../../../static/assets/theme/homepage__cycle.inline.svg";
import "./HomepageDesignCycle.scss";

function HomepageDesignCycle() {
  return (
    <section
      id="homepage__cycle"
      className="container grid grid--outer"
      style={{ alignItems: "center" }}
    >
      <div className="homepage__cycle__graphic" style={{textAlign: "center"}}>
        <Graphic style={{maxWidth: "24rem"}} />
      </div>

      <div className="homepage__cycle__text">
        <h2 className="homepage__cycle__text__heading">Design/Development Cycle</h2>
        <p>Design thinking makes projects better through every phase of the development cycle—it defines clear end-goals, builds roadmaps to get there, keeps development agile, and regularly evaluates its successes through data-driven criteria.</p>
      </div>

    </section>
    );
  }

export default HomepageDesignCycle;
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react";
import Logo from "../../../static/assets/theme/homepage__banner__logo.inline.svg";
import "./HomeBanner.scss";

function HomeBanner(props) {
  const { config } = props
  return (
    <StaticQuery
      query={graphql`
        query {
          backgroundImage: file(relativePath: { eq: "assets/theme/geometric-pattern.png" }) {
            childImageSharp {
              fluid(
                quality: 90,
                maxWidth: 1920
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          profileImage: file(relativePath: { eq: "assets/theme/profile-jeff_szuc-clear.png" }) {
            childImageSharp {
              gatsbyImageData(
                layout: FIXED,
                quality: 90,
                width: 248,
                placeholder: BLURRED
              )
            }
          }
        }
      `}
      render={data => (
          <section
            id="homepage__banner"
            className="inverse container grid grid--outer grid__padding banner--top"
            aria-labelledby="skip__target"
            style={{
              paddingTop: "6rem",
              backgroundColor: "var(--color--bg)",
              color: "var(--item--home-banner__text)",
              backgroundSize: "cover",
              backgroundPosition: "left top",
              alignContent: "end",
              backgroundImage: `url(${data.backgroundImage.childImageSharp.fluid.src}), linear-gradient(30deg, var(--item--banner-gradient--dark), var(--item--banner-gradient--light) 90%)`
            }}
          >

            <div className="banner__text">
              <Logo 
                style={{
                  height: "4.5rem",
                  width: "14.5rem",
                  marginBottom: "var(--spacing--sub3)",
                  verticalAlign: "middle",
                  transform: "translateY(-0.125em)",
                }}
              />
              <h1
                id="skip__target"
                className="banner__heading"
                style={{ 
                  width: "min-content",
                  fontSize: "var(--scale--6)",
                  lineHeight: "1",
                  marginBottom: "0",
                  marginTop: "0",
                  color: "var(--color--cta)"
                }}
              >
                
                {config.siteTitle}
              </h1>
              <span
                className="banner__subheading"
                style={{ 
                  textTransform: "uppercase",
                  fontSize: "var(--scale--1)",
                }}
              >
                {config.siteTitleAlt}
              </span>
              <p 
                className="banner__description"
                style={{
                  borderLeft: "var(--border-width--2) solid var(--color--cta)",
                  paddingLeft: "var(--spacing--sub2)",
                  marginBottom: "0",
                  fontSize: "var(--scale--sub1)"
                }}
              >
                {config.siteDescription}
              </p>
            </div>

            <div
              className="banner__callout homepage__banner__popup"
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div className="homepage__banner__popup__text">
                Hi. I&apos;m Jeff Szuc, a Product Designer and Front-end Developer. Did you know User-Centered Design methodologies add value to every phase of the product development cycle? It&apos;s true!
              </div>
              <GatsbyImage
                className="homepage__banner__popup__profile-img"
                image={data.profileImage.childImageSharp.gatsbyImageData}
                alt="Jeff Szuc"
                backgroundColor={{
                  backgroundColor: "var(--item--banner-gradient--light)",
                }}
              />
            </div>

          </section>
        )
      }
    />
  );
}

export default HomeBanner;

import React from "react"
import ArticleCard from '../ArticleCard/ArticleCard'
import SectionHeading from '../SectionHeading/SectionHeading'
import "./HomepageArticles.scss"



function HomepageArticles(props) {
  const { data } = props
  return (
    <section
      id="homepage__articles"
      className="inverse container grid grid--outer grid__padding"
      aria-labelledby="section-heading--articles"
      style={{backgroundColor: "var(--color--bg)"}}
    >
      <SectionHeading
        title="Case-studies and Design Journal"
        sub="Design Process"
        id="section-heading--articles"
        inverse
      />
    
      {data.edges.map(({ node }, i) => (
        <ArticleCard
          key={`article_${i}`}
          frontMatter={node.frontmatter}
          fields={node.fields}
        />
      ))}
    </section>
  )
}

export default HomepageArticles;
import React from "react";
import Graphic from "../../../static/assets/theme/homepage__journey.inline.svg";
import SectionHeading from "../SectionHeading/SectionHeading";
import "./HomepageTimeline.scss";

function HomepageTimeline() {
  return (
    <>
      <div className="homepage__timeline__angle--top" />
      <section
        id="homepage__timeline"
        className="container grid grid--outer inverse"
        style={{ minHeight: "90vh" }}
      >

        <Graphic className="timeline__illustration"/>

        <SectionHeading
          title="My Designer’s Journey"
          id="section-heading--timeline"
          inverse
        />
        <div className="timeline__textcolumn">
          <h3>Design is a Process</h3>
          <p>Design is about exploration and discovery. I have been a magazine and children's book illustrator, a gallery artist, and worked in animation. I've created graphics for the NBA, NHL, and NCAA. I've gone from building flash websites to WordPress, to single-page apps.</p>
        </div>
        <div className="timeline__textcolumn">
          <h3>What Have I Done Lately?</h3>
          <p>Nowadays, I’m 100% about UX, Accessibility, and Optimization. I design web apps, online games, and institutional websites. When not working, I take Computer Science classes at Cornell University, paint murals of birds, and run marathons (slowly).</p>
        </div>
      </section>
    </>
  );
}

export default HomepageTimeline;
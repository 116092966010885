import { graphql } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import config from "../../data/SiteConfig"
import Footer from "../components/Footer/Footer"
import HomeBanner from "../components/HomeBanner/HomeBanner"
import HomepageArticles from "../components/HomepageArticles/HomepageArticles"
import HomepageDesignCycle from "../components/HomepageDesignCycle/HomepageDesignCycle"
import HomepageFeatured from "../components/HomepageFeatured/HomepageFeatured"
import HomepageFit from "../components/HomepageFit/HomepageFit"
import HomepageIntro from "../components/HomepageIntro/HomepageIntro"
import HomepageTimeline from "../components/HomepageTimeline/HomepageTimeline"
import HomepageWork from "../components/HomepageWork/HomepageWork"
import Layout from "../components/Layout/Layout"
import "./index.scss"


const homepage = ({ data }) => {
  const { notFeatured, featured } = data
  return (
    <Layout>
      <Helmet title={`${config.siteTitle} | ${config.siteTitleAlt}`} />
      <main id="homepage" className="main--homepage">
        {/* banner */}
        <HomeBanner config={config} />
        {/* full stack definition */}
        <HomepageDesignCycle />
        {/* a good fit */}
        <HomepageIntro />
        {/* timeline bio */}
        <HomepageTimeline />
        {/* design cycle */}
        <HomepageFit />
        {/* project links */}
        <HomepageWork />
        {/* Featured Articles */}
        {featured.edges.map(({ node }, index) => (
          <HomepageFeatured
            frontmatter={node.frontmatter}
            fields={node.fields}
            key={index}
          />
          ))}
        {/* articles */}
        <HomepageArticles data={notFeatured} />        
      </main>
      {/* footer */}
      <Footer config={config} />
    </Layout>
  );
};
export default homepage;

export const query = graphql`
  query myArticles {
    featured: allMarkdownRemark(
      filter: {
        frontmatter: {
          draft: { ne: true },
          hidden: { ne: true },
          featured: { eq: true }
        }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            summary
            category
            tags
            type
            thumb {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED, 
                  width: 1920, 
                  quality: 90,
                  formats: [AUTO, WEBP]
                )
              }
            }
            banner {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED, 
                  width: 1920, 
                  quality: 90,
                  formats: [AUTO,WEBP]
                )
              }
            }
          }
          fields {
            slug
            date
          }
        }
      }
    }
    notFeatured: allMarkdownRemark(
      filter: {
        frontmatter: {
          draft: { ne: true },
          hidden: { ne: true },
          featured: { eq: false }
        }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 4
    ) {
      edges {
        node {
          ...IndexPostFragment
        }
      }
    }
  }
  `
